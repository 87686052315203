import { useTranslation } from 'react-i18next';

import Button from '@/components/forms/Button';
import React, { useEffect, useState } from 'react';
import { ErrorType } from '@/api/axios-instance';
import { AllPossibleAxiosErrorTypes } from '@/api/generated/allPossibleAxiosErrorTypes';
import { HttpStatusCode } from 'axios';

/**
 * @description Handle errors in the CoreServie validation errors format
 */
export const validateMessageFromErrorField = ({ field, isError = false, error }) => {
  if (!isError) return null;
  return error?.response?.data?.errors?.find((f) => f?.field === field)?.message;
};

/**
 * @description Use this when to display error from axios.
 */
export const AxiosErrorComponent = ({
  axiosError,
  children,
}: {
  axiosError: ErrorType<AllPossibleAxiosErrorTypes>;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const [translatedStatusErrors, setTranslatedStatusErrors] = useState<string[]>([]);
  const [hideResponseMessages, setHideResponseMessages] = useState(false);
  const [hideRedirectUrl, setHideRedirectUrl] = useState(false);

  useEffect(() => {
    if (axiosError?.response?.status === HttpStatusCode.TooManyRequests) {
      setTranslatedStatusErrors([...translatedStatusErrors, t(`web_error_requestThrottled`)]);
      setHideResponseMessages(true);
    } else if (axiosError?.response?.status === HttpStatusCode.Forbidden) {
      setTranslatedStatusErrors([...translatedStatusErrors, t(`expired_or_invalid_token`)]);
      setHideResponseMessages(true);
    } else {
      const error_key = `web_error_${axiosError?.response?.data.message.toLowerCase()}`;
      const translatedError = t(error_key);
      if (translatedError !== error_key) {
        setTranslatedStatusErrors([...translatedStatusErrors, translatedError]);
        setHideResponseMessages(true);
        setHideRedirectUrl(true);
      }
    }
  }, [axiosError]);

  return (
    <div className="border border-rose-500  b-slate-100 rounded-lg mt-8 mb-3 p-8">
      <h2 className="text-base">{t('web_errors_somethingWentWrongWithTheRequest')}.</h2>
      <ul className="mt-3">
        {translatedStatusErrors?.map((e) => (
          <li className="mt-1" key={e}>
            -{' '}
            {
              // eslint-disable-next-line react/no-danger
              <span className="terms" dangerouslySetInnerHTML={{ __html: e }} /> ?? ''
            }
          </li>
        ))}

        {!hideResponseMessages &&
          axiosError?.response?.data &&
          'errors' in axiosError.response.data &&
          // axiosError?.response?.data?.errors &&
          axiosError?.response?.data?.errors?.map((e) => (
            <li className="mt-1" key={e.message}>
              - {e?.field ?? ''} {e.message === e.message.toUpperCase() ? t(`web_errors_${e.message}`) : e.message}
            </li>
          ))}

        {!hideResponseMessages && !!axiosError?.response?.data?.message && (
          <li className="mt-1" key={axiosError?.response?.data?.message}>
            -&nbsp;
            {axiosError?.response?.data?.message === axiosError?.response?.data?.message.toUpperCase()
              ? t(`web_errors_${axiosError?.response?.data?.message}`)
              : axiosError?.response?.data?.message}
          </li>
        )}

        {!hideRedirectUrl &&
          !!axiosError?.response?.data &&
          'data' in axiosError.response.data &&
          !!axiosError?.response?.data?.data?.redirect_url && (
            <li className="mt-5 flex justify-center" key={`${axiosError?.response?.data?.message}-link`}>
              <a href={axiosError?.response?.data?.data?.redirect_url} aria-label="redirect-link">
                <Button type="submit">{t('web_errors_takeMeThere')}</Button>
              </a>
            </li>
          )}
        {children && <li className="mt-1">{children}</li>}
      </ul>
    </div>
  );
};
