import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '@/components/forms/Input';
import EyeIcon from '@/assets/icon-eye.svg';
import Checkmark from '../../assets/checkmark.svg?react';

const PasswordInput = ({ currentPassword, ...props }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const passwordMessage =
    String(currentPassword ?? '').length >= 10 ? (
      <div className="inline-block mr-2">
        <Checkmark />
        {t('web_forms_password_greatPassword')}
      </div>
    ) : (
      t('web_minimum_password_validation_message')
    );
  return (
    <div className="w-full">
      <div className="relative mt-1 rounded-md shadow-sm">
        <Input {...props} type={show ? 'text' : 'password'}>
          {!props?.errors?.[props?.name ?? 'password'] && (
            <span className="h-8 text-xs text-center lg:text-left">{passwordMessage}</span>
          )}
        </Input>
        <div
          onClick={() => setShow(!show)}
          className="absolute inset-y-0 right-0 flex items-center px-4 opacity-40 hover:opacity-60 hover:cursor-pointer"
        >
          <img className="w-6 h-6 text-gray-400" aria-hidden="true" src={EyeIcon} />
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
