/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Customer API <customer.nordiskfilmplus.com>
 * Public facing API for NF customers<br><br>
      Download: <a download href="/docs/postman-customer.json">Postman Collection</a>
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ConflictExceptionDto,
  ConsentsDto,
  CreateInitialPayment201,
  CreateInitialPaymentDto,
  CreateOrderDto,
  CreatePaymentDetailsDto,
  CreateSubscriptionSwitchPostBodyDto,
  DeleteSubscription200ResponseDto,
  DeleteSubscriptionDto,
  EmailDto,
  ForbiddenExceptioDto,
  GetAvailableConsentsFromToken200ResponseDto,
  GetCustomer200ResponseDto,
  GetCustomerConsents200ResponseDto,
  GetGetCode200ResponseDto,
  GetInvoice200ResponseDto,
  GetInvoices200ResponseDto,
  GetOfferSwitches200ResponseDto,
  GetOfferSwitchesParams,
  GetOffers200ResponseDto,
  GetSubscriptionSwitches200ResponseDto,
  GetSubscriptions200ResponseDto,
  GetVerifyCustomerConflictDto,
  GetVerifyCustomerOkDto,
  LoginTokenFromCodeDto,
  NotFoundExceptionDto,
  OrderItemDto,
  PatchReplacePassword200ResponseDto,
  PatchSubscription200ResponseDto,
  PaymentDetailsItemDto,
  PaymentMethodItemDto,
  PostCreatePaymentDetails201ResponseDto,
  PostGetLoginTokenFromCode200ResponseDto,
  PostLoginBodyDto,
  PostLoginResponseDto,
  PostLoginTokenBodyDto,
  PostLoginUnauthorizedExceptionDto,
  PostRefreshToken200ResponseDto,
  PostRegisterUnverifiedCustomerConflictDto,
  PostRegisterUnverifiedCustomerOkDto,
  PostResetPassword200ResponseDto,
  PostResetPassword428ResponseDto,
  PostValidateCustomer200ResponseDto,
  PostValidateCustomerConflictDto,
  PutCustomer200ResponseDto,
  PutCustomerDto,
  PutUpdateCustomerConsents200ResponseDto,
  PutVerifyCode200ResponseDto,
  RefreshTokensDto,
  ResetPasswordDto,
  SignupUnverifiedCustomerDto,
  SignupValidateCustomerDto,
  UnauthorizedExceptionDto,
  UnprocessableEntityDto,
  UpdatePasswordDto,
  UpdateSubscriptionDto,
  VerifyCodeDto
} from './customer.schemas'
import { customInstance } from '../axios-instance';
import type { ErrorType, BodyType } from '../axios-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * 
Initiate the customer creation process; this will send an email to the customer containing a link with a token that is used when validating and verifying the customer.

The verification token/link sent to the email lasts for 48h.
    
 * @summary Signup - New customer (step 1/3)
 */
export const registerUnverifiedCustomer = (
    signupUnverifiedCustomerDto: BodyType<SignupUnverifiedCustomerDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostRegisterUnverifiedCustomerOkDto>(
      {url: `/signup`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: signupUnverifiedCustomerDto
    },
      options);
    }
  


export const getRegisterUnverifiedCustomerMutationOptions = <TError = ErrorType<PostRegisterUnverifiedCustomerConflictDto | UnprocessableEntityDto | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registerUnverifiedCustomer>>, TError,{data: BodyType<SignupUnverifiedCustomerDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof registerUnverifiedCustomer>>, TError,{data: BodyType<SignupUnverifiedCustomerDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof registerUnverifiedCustomer>>, {data: BodyType<SignupUnverifiedCustomerDto>}> = (props) => {
          const {data} = props ?? {};

          return  registerUnverifiedCustomer(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RegisterUnverifiedCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof registerUnverifiedCustomer>>>
    export type RegisterUnverifiedCustomerMutationBody = BodyType<SignupUnverifiedCustomerDto>
    export type RegisterUnverifiedCustomerMutationError = ErrorType<PostRegisterUnverifiedCustomerConflictDto | UnprocessableEntityDto | void>

    /**
 * @summary Signup - New customer (step 1/3)
 */
export const useRegisterUnverifiedCustomer = <TError = ErrorType<PostRegisterUnverifiedCustomerConflictDto | UnprocessableEntityDto | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registerUnverifiedCustomer>>, TError,{data: BodyType<SignupUnverifiedCustomerDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof registerUnverifiedCustomer>>,
        TError,
        {data: BodyType<SignupUnverifiedCustomerDto>},
        TContext
      > => {

      const mutationOptions = getRegisterUnverifiedCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
The following endpoint is used to verify that the customer has followed the signup link from the sent email.

End user clicks link in verification-email and is directed to the verification website.
That website calls the `GET /verify/:customerToken`-endpoint to verify that the email link has been clicked.
    
 * @summary Signup - Verify email (step 2/3)
 */
export const verifyCustomer = (
    customerToken: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetVerifyCustomerOkDto>(
      {url: `/verify/${customerToken}`, method: 'GET', signal
    },
      options);
    }
  

export const getVerifyCustomerQueryKey = (customerToken: string,) => {
    return [`/verify/${customerToken}`] as const;
    }

    
export const getVerifyCustomerQueryOptions = <TData = Awaited<ReturnType<typeof verifyCustomer>>, TError = ErrorType<ForbiddenExceptioDto | GetVerifyCustomerConflictDto>>(customerToken: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof verifyCustomer>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVerifyCustomerQueryKey(customerToken);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyCustomer>>> = ({ signal }) => verifyCustomer(customerToken, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(customerToken), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof verifyCustomer>>, TError, TData> & { queryKey: QueryKey }
}

export type VerifyCustomerQueryResult = NonNullable<Awaited<ReturnType<typeof verifyCustomer>>>
export type VerifyCustomerQueryError = ErrorType<ForbiddenExceptioDto | GetVerifyCustomerConflictDto>

/**
 * @summary Signup - Verify email (step 2/3)
 */
export const useVerifyCustomer = <TData = Awaited<ReturnType<typeof verifyCustomer>>, TError = ErrorType<ForbiddenExceptioDto | GetVerifyCustomerConflictDto>>(
 customerToken: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof verifyCustomer>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVerifyCustomerQueryOptions(customerToken,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 
The following endpoint is used to validate the customer and update the customer details and is the final step before customer account creation is completed.

- Before using this endpoint make sure the customer is verified `GET /verify/:customerToken`.
- Use `GET /available-consents/:customerToken` to populate consents in the request body.

The `loginToken` (2 minutes expiration date) from the response can be used together with `POST /login/:loginToken` to get a one time only login.
    
 * @summary Signup - Validate (step 3/3)
 */
export const validateCustomer = (
    customerToken: string,
    signupValidateCustomerDto: BodyType<SignupValidateCustomerDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostValidateCustomer200ResponseDto>(
      {url: `/signup/${customerToken}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: signupValidateCustomerDto
    },
      options);
    }
  


export const getValidateCustomerMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | PostValidateCustomerConflictDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateCustomer>>, TError,{customerToken: string;data: BodyType<SignupValidateCustomerDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof validateCustomer>>, TError,{customerToken: string;data: BodyType<SignupValidateCustomerDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof validateCustomer>>, {customerToken: string;data: BodyType<SignupValidateCustomerDto>}> = (props) => {
          const {customerToken,data} = props ?? {};

          return  validateCustomer(customerToken,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ValidateCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof validateCustomer>>>
    export type ValidateCustomerMutationBody = BodyType<SignupValidateCustomerDto>
    export type ValidateCustomerMutationError = ErrorType<ForbiddenExceptioDto | PostValidateCustomerConflictDto | UnprocessableEntityDto>

    /**
 * @summary Signup - Validate (step 3/3)
 */
export const useValidateCustomer = <TError = ErrorType<ForbiddenExceptioDto | PostValidateCustomerConflictDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateCustomer>>, TError,{customerToken: string;data: BodyType<SignupValidateCustomerDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof validateCustomer>>,
        TError,
        {customerToken: string;data: BodyType<SignupValidateCustomerDto>},
        TContext
      > => {

      const mutationOptions = getValidateCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const verifyMarketingCustomer = (
    customerToken: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<unknown>(
      {url: `/verify-marketing/${customerToken}`, method: 'GET', signal
    },
      options);
    }
  

export const getVerifyMarketingCustomerQueryKey = (customerToken: string,) => {
    return [`/verify-marketing/${customerToken}`] as const;
    }

    
export const getVerifyMarketingCustomerQueryOptions = <TData = Awaited<ReturnType<typeof verifyMarketingCustomer>>, TError = ErrorType<unknown>>(customerToken: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof verifyMarketingCustomer>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVerifyMarketingCustomerQueryKey(customerToken);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyMarketingCustomer>>> = ({ signal }) => verifyMarketingCustomer(customerToken, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(customerToken), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof verifyMarketingCustomer>>, TError, TData> & { queryKey: QueryKey }
}

export type VerifyMarketingCustomerQueryResult = NonNullable<Awaited<ReturnType<typeof verifyMarketingCustomer>>>
export type VerifyMarketingCustomerQueryError = ErrorType<unknown>

export const useVerifyMarketingCustomer = <TData = Awaited<ReturnType<typeof verifyMarketingCustomer>>, TError = ErrorType<unknown>>(
 customerToken: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof verifyMarketingCustomer>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVerifyMarketingCustomerQueryOptions(customerToken,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 
Used by customers after signup validation and redirected away from auth.* domain and big-screen.

This endpoint can be used after the the last signup validation step is completed and the client is redirected together with the `loginToken`.

Example redirect from the auth.nordiskfilmplus.com domain:
`https://test.nordiskfilmplus.com/dk/da/signup/payment?loginToken=3skTRZdter21p2j`

Use the `POST /login/{loginToken}` endpoint to retrieve the ID token and refresh token, which are used to authenticate future API requests. 


All secured endpoints require a bearer token in the Authorization header, in the format `Bearer <idToken>`.
    
 * @summary Login from token
 */
export const loginByToken = (
    loginToken: string,
    postLoginTokenBodyDto: BodyType<PostLoginTokenBodyDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostLoginResponseDto>(
      {url: `/login/${loginToken}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postLoginTokenBodyDto
    },
      options);
    }
  


export const getLoginByTokenMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginByToken>>, TError,{loginToken: string;data: BodyType<PostLoginTokenBodyDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof loginByToken>>, TError,{loginToken: string;data: BodyType<PostLoginTokenBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginByToken>>, {loginToken: string;data: BodyType<PostLoginTokenBodyDto>}> = (props) => {
          const {loginToken,data} = props ?? {};

          return  loginByToken(loginToken,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoginByTokenMutationResult = NonNullable<Awaited<ReturnType<typeof loginByToken>>>
    export type LoginByTokenMutationBody = BodyType<PostLoginTokenBodyDto>
    export type LoginByTokenMutationError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto>

    /**
 * @summary Login from token
 */
export const useLoginByToken = <TError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginByToken>>, TError,{loginToken: string;data: BodyType<PostLoginTokenBodyDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof loginByToken>>,
        TError,
        {loginToken: string;data: BodyType<PostLoginTokenBodyDto>},
        TContext
      > => {

      const mutationOptions = getLoginByTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
Use the `POST /login` endpoint to retrieve the ID token and refresh token, which are used to authenticate future API requests.

All secured endpoints require a bearer token in the Authorization header, in the format `Bearer <idToken>`.
 * @summary Login
 */
export const login = (
    postLoginBodyDto: BodyType<PostLoginBodyDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostLoginResponseDto>(
      {url: `/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postLoginBodyDto
    },
      options);
    }
  


export const getLoginMutationOptions = <TError = ErrorType<PostLoginUnauthorizedExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: BodyType<PostLoginBodyDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: BodyType<PostLoginBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof login>>, {data: BodyType<PostLoginBodyDto>}> = (props) => {
          const {data} = props ?? {};

          return  login(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoginMutationResult = NonNullable<Awaited<ReturnType<typeof login>>>
    export type LoginMutationBody = BodyType<PostLoginBodyDto>
    export type LoginMutationError = ErrorType<PostLoginUnauthorizedExceptionDto | UnprocessableEntityDto>

    /**
 * @summary Login
 */
export const useLogin = <TError = ErrorType<PostLoginUnauthorizedExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: BodyType<PostLoginBodyDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof login>>,
        TError,
        {data: BodyType<PostLoginBodyDto>},
        TContext
      > => {

      const mutationOptions = getLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
Used to trigger the forgot password email sent to the customer.

Forgot password token last for 10m.
    
If the customer tries to reset password without being VERIFIED or VALIDATED the email sent will be "Account Verification" instead.
    
 * @summary Forgot password
 */
export const forgotPassword = (
    emailDto: BodyType<EmailDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<unknown>(
      {url: `/forgot-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: emailDto
    },
      options);
    }
  


export const getForgotPasswordMutationOptions = <TError = ErrorType<UnprocessableEntityDto | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof forgotPassword>>, TError,{data: BodyType<EmailDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof forgotPassword>>, TError,{data: BodyType<EmailDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof forgotPassword>>, {data: BodyType<EmailDto>}> = (props) => {
          const {data} = props ?? {};

          return  forgotPassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof forgotPassword>>>
    export type ForgotPasswordMutationBody = BodyType<EmailDto>
    export type ForgotPasswordMutationError = ErrorType<UnprocessableEntityDto | void>

    /**
 * @summary Forgot password
 */
export const useForgotPassword = <TError = ErrorType<UnprocessableEntityDto | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof forgotPassword>>, TError,{data: BodyType<EmailDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof forgotPassword>>,
        TError,
        {data: BodyType<EmailDto>},
        TContext
      > => {

      const mutationOptions = getForgotPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
Endpoint used to reset the password of a customer, token is from the "forgot password" email the customer has received.

After successfully updating the password, the customer will be logged out on all devices within 1 hour.
    
 * @summary Reset password
 */
export const resetPassword = (
    customerToken: string,
    resetPasswordDto: BodyType<ResetPasswordDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostResetPassword200ResponseDto>(
      {url: `/reset-password/${customerToken}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resetPasswordDto
    },
      options);
    }
  


export const getResetPasswordMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto | PostResetPassword428ResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{customerToken: string;data: BodyType<ResetPasswordDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{customerToken: string;data: BodyType<ResetPasswordDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetPassword>>, {customerToken: string;data: BodyType<ResetPasswordDto>}> = (props) => {
          const {customerToken,data} = props ?? {};

          return  resetPassword(customerToken,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof resetPassword>>>
    export type ResetPasswordMutationBody = BodyType<ResetPasswordDto>
    export type ResetPasswordMutationError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto | PostResetPassword428ResponseDto>

    /**
 * @summary Reset password
 */
export const useResetPassword = <TError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto | PostResetPassword428ResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{customerToken: string;data: BodyType<ResetPasswordDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof resetPassword>>,
        TError,
        {customerToken: string;data: BodyType<ResetPasswordDto>},
        TContext
      > => {

      const mutationOptions = getResetPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
Used by customers to replace their current password when logged in.

After successfully updating the password, the customer will be logged out on all devices within 1 hour.

Use the `POST /login/{loginToken}` endpoint to retrieve new credentials with the `login_token` from the response of this endpoint. 
    
 * @summary Update password (logged in)
 */
export const replacePassword = (
    updatePasswordDto: BodyType<UpdatePasswordDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchReplacePassword200ResponseDto>(
      {url: `/password`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updatePasswordDto
    },
      options);
    }
  


export const getReplacePasswordMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof replacePassword>>, TError,{data: BodyType<UpdatePasswordDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof replacePassword>>, TError,{data: BodyType<UpdatePasswordDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof replacePassword>>, {data: BodyType<UpdatePasswordDto>}> = (props) => {
          const {data} = props ?? {};

          return  replacePassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ReplacePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof replacePassword>>>
    export type ReplacePasswordMutationBody = BodyType<UpdatePasswordDto>
    export type ReplacePasswordMutationError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto>

    /**
 * @summary Update password (logged in)
 */
export const useReplacePassword = <TError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof replacePassword>>, TError,{data: BodyType<UpdatePasswordDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof replacePassword>>,
        TError,
        {data: BodyType<UpdatePasswordDto>},
        TContext
      > => {

      const mutationOptions = getReplacePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
This endpoint can be used to grant a new JWT `idToken` in order to extend user session.

All secured endpoints require a bearer token in the Authorization header, in the format `Bearer <idToken>`.
  
 * @summary Refresh JWT-token (idToken)
 */
export const refreshToken = (
    refreshTokensDto: BodyType<RefreshTokensDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostRefreshToken200ResponseDto>(
      {url: `/refresh-tokens`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: refreshTokensDto
    },
      options);
    }
  


export const getRefreshTokenMutationOptions = <TError = ErrorType<UnauthorizedExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refreshToken>>, TError,{data: BodyType<RefreshTokensDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof refreshToken>>, TError,{data: BodyType<RefreshTokensDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof refreshToken>>, {data: BodyType<RefreshTokensDto>}> = (props) => {
          const {data} = props ?? {};

          return  refreshToken(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RefreshTokenMutationResult = NonNullable<Awaited<ReturnType<typeof refreshToken>>>
    export type RefreshTokenMutationBody = BodyType<RefreshTokensDto>
    export type RefreshTokenMutationError = ErrorType<UnauthorizedExceptionDto | UnprocessableEntityDto>

    /**
 * @summary Refresh JWT-token (idToken)
 */
export const useRefreshToken = <TError = ErrorType<UnauthorizedExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refreshToken>>, TError,{data: BodyType<RefreshTokensDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof refreshToken>>,
        TError,
        {data: BodyType<RefreshTokensDto>},
        TContext
      > => {

      const mutationOptions = getRefreshTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
This endpoint invalidates all refresh tokens and ott sessions tokens. It can be used to implement logout from all devices functionality.

The `idToken` is still valid for up to 1 hour while `refreshToken` and `ottToken` are invalidated right away. After that, the customer will be logged out. 
  
 * @summary Logout from all devices (Invalidate refresh tokens)
 */
export const revokeRefreshTokens = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<unknown>(
      {url: `/refresh-tokens`, method: 'DELETE'
    },
      options);
    }
  


export const getRevokeRefreshTokensMutationOptions = <TError = ErrorType<ForbiddenExceptioDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof revokeRefreshTokens>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof revokeRefreshTokens>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof revokeRefreshTokens>>, void> = () => {
          

          return  revokeRefreshTokens(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RevokeRefreshTokensMutationResult = NonNullable<Awaited<ReturnType<typeof revokeRefreshTokens>>>
    
    export type RevokeRefreshTokensMutationError = ErrorType<ForbiddenExceptioDto>

    /**
 * @summary Logout from all devices (Invalidate refresh tokens)
 */
export const useRevokeRefreshTokens = <TError = ErrorType<ForbiddenExceptioDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof revokeRefreshTokens>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof revokeRefreshTokens>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getRevokeRefreshTokensMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
This endpoint invalidates ONE refresh token. Ott sessions tokens `ottToken` remains valid.

The `idToken` is still valid for up to 1 hour while the `refreshToken` is invalidated right away. After that, the customer will be logged out. 
  
 * @summary Logout
 */
export const revokeRefreshToken = (
    refreshTokensDto: BodyType<RefreshTokensDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<unknown>(
      {url: `/logout`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: refreshTokensDto
    },
      options);
    }
  


export const getRevokeRefreshTokenMutationOptions = <TError = ErrorType<ForbiddenExceptioDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof revokeRefreshToken>>, TError,{data: BodyType<RefreshTokensDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof revokeRefreshToken>>, TError,{data: BodyType<RefreshTokensDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof revokeRefreshToken>>, {data: BodyType<RefreshTokensDto>}> = (props) => {
          const {data} = props ?? {};

          return  revokeRefreshToken(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RevokeRefreshTokenMutationResult = NonNullable<Awaited<ReturnType<typeof revokeRefreshToken>>>
    export type RevokeRefreshTokenMutationBody = BodyType<RefreshTokensDto>
    export type RevokeRefreshTokenMutationError = ErrorType<ForbiddenExceptioDto>

    /**
 * @summary Logout
 */
export const useRevokeRefreshToken = <TError = ErrorType<ForbiddenExceptioDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof revokeRefreshToken>>, TError,{data: BodyType<RefreshTokensDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof revokeRefreshToken>>,
        TError,
        {data: BodyType<RefreshTokensDto>},
        TContext
      > => {

      const mutationOptions = getRevokeRefreshTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
Fetch public NF+ consents from the `customerToken`.
It returns the actual consents version, label.

Consents can be used in the registration page to enable accept/decline it by customer.
  
 * @summary Get available consents
 */
export const availableConsentsFromToken = (
    customerToken: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetAvailableConsentsFromToken200ResponseDto>(
      {url: `/available-consents/${customerToken}`, method: 'GET', signal
    },
      options);
    }
  

export const getAvailableConsentsFromTokenQueryKey = (customerToken: string,) => {
    return [`/available-consents/${customerToken}`] as const;
    }

    
export const getAvailableConsentsFromTokenQueryOptions = <TData = Awaited<ReturnType<typeof availableConsentsFromToken>>, TError = ErrorType<ForbiddenExceptioDto>>(customerToken: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof availableConsentsFromToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAvailableConsentsFromTokenQueryKey(customerToken);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof availableConsentsFromToken>>> = ({ signal }) => availableConsentsFromToken(customerToken, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(customerToken), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof availableConsentsFromToken>>, TError, TData> & { queryKey: QueryKey }
}

export type AvailableConsentsFromTokenQueryResult = NonNullable<Awaited<ReturnType<typeof availableConsentsFromToken>>>
export type AvailableConsentsFromTokenQueryError = ErrorType<ForbiddenExceptioDto>

/**
 * @summary Get available consents
 */
export const useAvailableConsentsFromToken = <TData = Awaited<ReturnType<typeof availableConsentsFromToken>>, TError = ErrorType<ForbiddenExceptioDto>>(
 customerToken: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof availableConsentsFromToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAvailableConsentsFromTokenQueryOptions(customerToken,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Lists the available consents for a logged in customer.
 * @summary Get available consents
 */
export const availableConsents = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetAvailableConsentsFromToken200ResponseDto>(
      {url: `/available-consents`, method: 'GET', signal
    },
      options);
    }
  

export const getAvailableConsentsQueryKey = () => {
    return [`/available-consents`] as const;
    }

    
export const getAvailableConsentsQueryOptions = <TData = Awaited<ReturnType<typeof availableConsents>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof availableConsents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAvailableConsentsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof availableConsents>>> = ({ signal }) => availableConsents(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof availableConsents>>, TError, TData> & { queryKey: QueryKey }
}

export type AvailableConsentsQueryResult = NonNullable<Awaited<ReturnType<typeof availableConsents>>>
export type AvailableConsentsQueryError = ErrorType<unknown>

/**
 * @summary Get available consents
 */
export const useAvailableConsents = <TData = Awaited<ReturnType<typeof availableConsents>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof availableConsents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAvailableConsentsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Lists the consents the customer has approved/agreed to.
 * @summary Get customer consents
 */
export const customerConsents = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetCustomerConsents200ResponseDto>(
      {url: `/consents`, method: 'GET', signal
    },
      options);
    }
  

export const getCustomerConsentsQueryKey = () => {
    return [`/consents`] as const;
    }

    
export const getCustomerConsentsQueryOptions = <TData = Awaited<ReturnType<typeof customerConsents>>, TError = ErrorType<ForbiddenExceptioDto>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerConsents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomerConsentsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof customerConsents>>> = ({ signal }) => customerConsents(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof customerConsents>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomerConsentsQueryResult = NonNullable<Awaited<ReturnType<typeof customerConsents>>>
export type CustomerConsentsQueryError = ErrorType<ForbiddenExceptioDto>

/**
 * @summary Get customer consents
 */
export const useCustomerConsents = <TData = Awaited<ReturnType<typeof customerConsents>>, TError = ErrorType<ForbiddenExceptioDto>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerConsents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomerConsentsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * The following endpoint is used to update the consent response of a customer: if they accept or decline the consent.
 * @summary Update customer consents
 */
export const updateCustomerConsents = (
    consentsDto: BodyType<ConsentsDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PutUpdateCustomerConsents200ResponseDto>(
      {url: `/consents`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: consentsDto
    },
      options);
    }
  


export const getUpdateCustomerConsentsMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCustomerConsents>>, TError,{data: BodyType<ConsentsDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCustomerConsents>>, TError,{data: BodyType<ConsentsDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCustomerConsents>>, {data: BodyType<ConsentsDto>}> = (props) => {
          const {data} = props ?? {};

          return  updateCustomerConsents(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCustomerConsentsMutationResult = NonNullable<Awaited<ReturnType<typeof updateCustomerConsents>>>
    export type UpdateCustomerConsentsMutationBody = BodyType<ConsentsDto>
    export type UpdateCustomerConsentsMutationError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>

    /**
 * @summary Update customer consents
 */
export const useUpdateCustomerConsents = <TError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCustomerConsents>>, TError,{data: BodyType<ConsentsDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateCustomerConsents>>,
        TError,
        {data: BodyType<ConsentsDto>},
        TContext
      > => {

      const mutationOptions = getUpdateCustomerConsentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Lists all transactions(invoices) of the customer.
 * @summary Get Invoices
 */
export const getInvoices = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetInvoices200ResponseDto>(
      {url: `/invoices`, method: 'GET', signal
    },
      options);
    }
  

export const getGetInvoicesQueryKey = () => {
    return [`/invoices`] as const;
    }

    
export const getGetInvoicesQueryOptions = <TData = Awaited<ReturnType<typeof getInvoices>>, TError = ErrorType<ForbiddenExceptioDto>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInvoicesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoices>>> = ({ signal }) => getInvoices(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoices>>>
export type GetInvoicesQueryError = ErrorType<ForbiddenExceptioDto>

/**
 * @summary Get Invoices
 */
export const useGetInvoices = <TData = Awaited<ReturnType<typeof getInvoices>>, TError = ErrorType<ForbiddenExceptioDto>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInvoicesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * List a specific transaction(invoice) belonging to the customer.
 * @summary Get Invoice
 */
export const getInvoice = (
    invoiceId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetInvoice200ResponseDto>(
      {url: `/invoices/${invoiceId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetInvoiceQueryKey = (invoiceId: string,) => {
    return [`/invoices/${invoiceId}`] as const;
    }

    
export const getGetInvoiceQueryOptions = <TData = Awaited<ReturnType<typeof getInvoice>>, TError = ErrorType<ForbiddenExceptioDto>>(invoiceId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInvoice>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInvoiceQueryKey(invoiceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoice>>> = ({ signal }) => getInvoice(invoiceId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(invoiceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInvoice>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInvoiceQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoice>>>
export type GetInvoiceQueryError = ErrorType<ForbiddenExceptioDto>

/**
 * @summary Get Invoice
 */
export const useGetInvoice = <TData = Awaited<ReturnType<typeof getInvoice>>, TError = ErrorType<ForbiddenExceptioDto>>(
 invoiceId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInvoice>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInvoiceQueryOptions(invoiceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 
List a specific invoice belonging to the customer. Respone is the invoice as a PDF-file.

Make sure the request adds headers for:
`Content-Type: "application/pdf"`
`Accept: "application/pdf"`
    
 * @summary Get Invoice as PDF
 */
export const getInvoiceMockPdf = (
    invoiceId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<unknown>(
      {url: `/invoices/pdf/${invoiceId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetInvoiceMockPdfQueryKey = (invoiceId: string,) => {
    return [`/invoices/pdf/${invoiceId}`] as const;
    }

    
export const getGetInvoiceMockPdfQueryOptions = <TData = Awaited<ReturnType<typeof getInvoiceMockPdf>>, TError = ErrorType<ForbiddenExceptioDto>>(invoiceId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInvoiceMockPdf>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInvoiceMockPdfQueryKey(invoiceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoiceMockPdf>>> = ({ signal }) => getInvoiceMockPdf(invoiceId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(invoiceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInvoiceMockPdf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInvoiceMockPdfQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoiceMockPdf>>>
export type GetInvoiceMockPdfQueryError = ErrorType<ForbiddenExceptioDto>

/**
 * @summary Get Invoice as PDF
 */
export const useGetInvoiceMockPdf = <TData = Awaited<ReturnType<typeof getInvoiceMockPdf>>, TError = ErrorType<ForbiddenExceptioDto>>(
 invoiceId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInvoiceMockPdf>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInvoiceMockPdfQueryOptions(invoiceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 
## Description
List all offers that are available for purchase by the customer.
This endpoint only returns offers available in the customers country(country is auto assigned in the signup step).
  
 * @summary Get available offers
 */
export const getSubscriptionOffers = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetOffers200ResponseDto>(
      {url: `/offers`, method: 'GET', signal
    },
      options);
    }
  

export const getGetSubscriptionOffersQueryKey = () => {
    return [`/offers`] as const;
    }

    
export const getGetSubscriptionOffersQueryOptions = <TData = Awaited<ReturnType<typeof getSubscriptionOffers>>, TError = ErrorType<ForbiddenExceptioDto>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionOffers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSubscriptionOffersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionOffers>>> = ({ signal }) => getSubscriptionOffers(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionOffers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSubscriptionOffersQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionOffers>>>
export type GetSubscriptionOffersQueryError = ErrorType<ForbiddenExceptioDto>

/**
 * @summary Get available offers
 */
export const useGetSubscriptionOffers = <TData = Awaited<ReturnType<typeof getSubscriptionOffers>>, TError = ErrorType<ForbiddenExceptioDto>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionOffers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSubscriptionOffersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 
The following endpoint is used to fetch the available payment methods based on the customers country.
You can fetch it once and use them for all your orders.
    
 * @summary Get payment methods
 */
export const getPaymentMethods = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PaymentMethodItemDto[]>(
      {url: `/payment-methods`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPaymentMethodsQueryKey = () => {
    return [`/payment-methods`] as const;
    }

    
export const getGetPaymentMethodsQueryOptions = <TData = Awaited<ReturnType<typeof getPaymentMethods>>, TError = ErrorType<ForbiddenExceptioDto>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethods>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPaymentMethodsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentMethods>>> = ({ signal }) => getPaymentMethods(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethods>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPaymentMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentMethods>>>
export type GetPaymentMethodsQueryError = ErrorType<ForbiddenExceptioDto>

/**
 * @summary Get payment methods
 */
export const useGetPaymentMethods = <TData = Awaited<ReturnType<typeof getPaymentMethods>>, TError = ErrorType<ForbiddenExceptioDto>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethods>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPaymentMethodsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 
The following endpoint is used to fetch the available payment methods based on the customers country.
You can fetch it once and use them for all your orders.
    
 * @summary Create order
 */
export const createOrder = (
    createOrderDto: BodyType<CreateOrderDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<OrderItemDto>(
      {url: `/orders`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrderDto
    },
      options);
    }
  


export const getCreateOrderMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrder>>, TError,{data: BodyType<CreateOrderDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createOrder>>, TError,{data: BodyType<CreateOrderDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOrder>>, {data: BodyType<CreateOrderDto>}> = (props) => {
          const {data} = props ?? {};

          return  createOrder(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrderMutationResult = NonNullable<Awaited<ReturnType<typeof createOrder>>>
    export type CreateOrderMutationBody = BodyType<CreateOrderDto>
    export type CreateOrderMutationError = ErrorType<ForbiddenExceptioDto | ConflictExceptionDto | UnprocessableEntityDto>

    /**
 * @summary Create order
 */
export const useCreateOrder = <TError = ErrorType<ForbiddenExceptioDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrder>>, TError,{data: BodyType<CreateOrderDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createOrder>>,
        TError,
        {data: BodyType<CreateOrderDto>},
        TContext
      > => {

      const mutationOptions = getCreateOrderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
Default URLs used set in the backend:
- "okUrl": "https://test.nordiskfilmplus.com/dk/da/payment?success=true"
- "failUrl": "https://test.nordiskfilmplus.com/dk/da/payment?success=false"
- "formUrl": "https://test.nordiskfilmplus.com/api/payments/payment-form"
- "redirectUrl": "https://test.nordiskfilmplus.com/dk/da/payment-details-3ds-redirect"

The iframe will redirect to these pages.

This endpoint can return TWO types of responsed depending on how you create the order.
The response is based on `required_payment_details` from the order response body, `required_payment_details` can be either `true` or `false`.

Example: If you apply 100% discount code to your order, `required_payment_details` will be false.
    
 * @summary Create initial payment
 */
export const createInitialPayment = (
    createInitialPaymentDto: BodyType<CreateInitialPaymentDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<CreateInitialPayment201>(
      {url: `/initial-payment`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createInitialPaymentDto
    },
      options);
    }
  


export const getCreateInitialPaymentMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInitialPayment>>, TError,{data: BodyType<CreateInitialPaymentDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createInitialPayment>>, TError,{data: BodyType<CreateInitialPaymentDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createInitialPayment>>, {data: BodyType<CreateInitialPaymentDto>}> = (props) => {
          const {data} = props ?? {};

          return  createInitialPayment(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateInitialPaymentMutationResult = NonNullable<Awaited<ReturnType<typeof createInitialPayment>>>
    export type CreateInitialPaymentMutationBody = BodyType<CreateInitialPaymentDto>
    export type CreateInitialPaymentMutationError = ErrorType<ForbiddenExceptioDto | ConflictExceptionDto | UnprocessableEntityDto>

    /**
 * @summary Create initial payment
 */
export const useCreateInitialPayment = <TError = ErrorType<ForbiddenExceptioDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInitialPayment>>, TError,{data: BodyType<CreateInitialPaymentDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createInitialPayment>>,
        TError,
        {data: BodyType<CreateInitialPaymentDto>},
        TContext
      > => {

      const mutationOptions = getCreateInitialPaymentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
The flow looks similar to the initial payment. This process will add new payment details that will be used for recurring. The old payment details are deleted.
The newly created payment details will be set as the default method for the next recurring payment.

@new - New Upodi flow, uses `payment_method_id`
- `payment_method_id` (optional) => Is used to determine what payment gateway to use. Leave out/undefined to let the backend decide the default.


@deprecated - Old Cleeng flow, replaced by Upodi flow that uses `payment_method_id`
`payment_details_id` - required - Payment details id that you want to replace. User payment details may be listed using:
`GET /payment-details` 

Example views needed in client: 
- "okUrl": "https://test.nordiskfilmplus.com/dk/da/payment-details?success=true"
- "failUrl": "https://test.nordiskfilmplus.com/dk/da/payment-details?success=false"
- "formUrl": "https://test.nordiskfilmplus.com/dk/da/payment-details-form"
- "redirectUrl": "https://test.nordiskfilmplus.com/dk/da/payment-details-3ds-redirect"
    
 * @summary Create payment details
 */
export const createPaymentDetails = (
    createPaymentDetailsDto: BodyType<CreatePaymentDetailsDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostCreatePaymentDetails201ResponseDto>(
      {url: `/payment-details`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createPaymentDetailsDto
    },
      options);
    }
  


export const getCreatePaymentDetailsMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPaymentDetails>>, TError,{data: BodyType<CreatePaymentDetailsDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createPaymentDetails>>, TError,{data: BodyType<CreatePaymentDetailsDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPaymentDetails>>, {data: BodyType<CreatePaymentDetailsDto>}> = (props) => {
          const {data} = props ?? {};

          return  createPaymentDetails(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreatePaymentDetailsMutationResult = NonNullable<Awaited<ReturnType<typeof createPaymentDetails>>>
    export type CreatePaymentDetailsMutationBody = BodyType<CreatePaymentDetailsDto>
    export type CreatePaymentDetailsMutationError = ErrorType<ForbiddenExceptioDto | ConflictExceptionDto | UnprocessableEntityDto>

    /**
 * @summary Create payment details
 */
export const useCreatePaymentDetails = <TError = ErrorType<ForbiddenExceptioDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPaymentDetails>>, TError,{data: BodyType<CreatePaymentDetailsDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createPaymentDetails>>,
        TError,
        {data: BodyType<CreatePaymentDetailsDto>},
        TContext
      > => {

      const mutationOptions = getCreatePaymentDetailsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
To retrieve a customer payment details, you use payment details resource.
Payment Details hold selected information (e.g. card expiration date) of the complete set of payment details or tokens to reference this data.
It is used for displaying and processing Payments with the Payment Gateway.
Only available for given user after confirmed Payment.
    
 * @summary Get payment details
 */
export const getPaymentDetails = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PaymentDetailsItemDto[]>(
      {url: `/payment-details`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPaymentDetailsQueryKey = () => {
    return [`/payment-details`] as const;
    }

    
export const getGetPaymentDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getPaymentDetails>>, TError = ErrorType<ForbiddenExceptioDto>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentDetails>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPaymentDetailsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentDetails>>> = ({ signal }) => getPaymentDetails(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPaymentDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPaymentDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentDetails>>>
export type GetPaymentDetailsQueryError = ErrorType<ForbiddenExceptioDto>

/**
 * @summary Get payment details
 */
export const useGetPaymentDetails = <TData = Awaited<ReturnType<typeof getPaymentDetails>>, TError = ErrorType<ForbiddenExceptioDto>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentDetails>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPaymentDetailsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 
The following endpoint is used to fetch the available subscriptions.
This endpoint lists all active, hold & cancelled subscriptions, but it doesn't return expired/terminated ones.

If 'switch_to_offer_id' is not NULL => The pending switch will be applied at the expiration date.
  
 * @summary Get subscriptions
 */
export const getAllSubscriptions = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetSubscriptions200ResponseDto>(
      {url: `/subscriptions`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAllSubscriptionsQueryKey = () => {
    return [`/subscriptions`] as const;
    }

    
export const getGetAllSubscriptionsQueryOptions = <TData = Awaited<ReturnType<typeof getAllSubscriptions>>, TError = ErrorType<ForbiddenExceptioDto>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSubscriptions>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllSubscriptionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllSubscriptions>>> = ({ signal }) => getAllSubscriptions(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllSubscriptions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllSubscriptionsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllSubscriptions>>>
export type GetAllSubscriptionsQueryError = ErrorType<ForbiddenExceptioDto>

/**
 * @summary Get subscriptions
 */
export const useGetAllSubscriptions = <TData = Awaited<ReturnType<typeof getAllSubscriptions>>, TError = ErrorType<ForbiddenExceptioDto>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSubscriptions>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllSubscriptionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 
The following endpoint is used to update subscription details, such as applying a voucher or re-activating a cancelled subscription.

The re-activate/apply copuon is an async flow and needs to wait for the webhook to update the status of the subscription.
Keep polling the `GET /subscriptions` endpoint to check the status of the subscription.
  
 * @summary Update subscription
 */
export const patchSubscription = (
    subscriptionId: number,
    updateSubscriptionDto: BodyType<UpdateSubscriptionDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchSubscription200ResponseDto>(
      {url: `/subscriptions/${subscriptionId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateSubscriptionDto
    },
      options);
    }
  


export const getPatchSubscriptionMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchSubscription>>, TError,{subscriptionId: number;data: BodyType<UpdateSubscriptionDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchSubscription>>, TError,{subscriptionId: number;data: BodyType<UpdateSubscriptionDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchSubscription>>, {subscriptionId: number;data: BodyType<UpdateSubscriptionDto>}> = (props) => {
          const {subscriptionId,data} = props ?? {};

          return  patchSubscription(subscriptionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof patchSubscription>>>
    export type PatchSubscriptionMutationBody = BodyType<UpdateSubscriptionDto>
    export type PatchSubscriptionMutationError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>

    /**
 * @summary Update subscription
 */
export const usePatchSubscription = <TError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchSubscription>>, TError,{subscriptionId: number;data: BodyType<UpdateSubscriptionDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchSubscription>>,
        TError,
        {subscriptionId: number;data: BodyType<UpdateSubscriptionDto>},
        TContext
      > => {

      const mutationOptions = getPatchSubscriptionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
This endpoint is used to cancel a customer's subscription so it will not renew after the expiration date.

The cancellation is an async flow and needs to wait for the webhook to update the status of the subscription.
Keep polling the `GET /subscriptions` endpoint to check the status of the subscription.
    
 * @summary Cancel subscription
 */
export const deleteSubscription = (
    subscriptionId: number,
    deleteSubscriptionDto: BodyType<DeleteSubscriptionDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<DeleteSubscription200ResponseDto>(
      {url: `/subscriptions/${subscriptionId}`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteSubscriptionDto
    },
      options);
    }
  


export const getDeleteSubscriptionMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSubscription>>, TError,{subscriptionId: number;data: BodyType<DeleteSubscriptionDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteSubscription>>, TError,{subscriptionId: number;data: BodyType<DeleteSubscriptionDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSubscription>>, {subscriptionId: number;data: BodyType<DeleteSubscriptionDto>}> = (props) => {
          const {subscriptionId,data} = props ?? {};

          return  deleteSubscription(subscriptionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSubscription>>>
    export type DeleteSubscriptionMutationBody = BodyType<DeleteSubscriptionDto>
    export type DeleteSubscriptionMutationError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>

    /**
 * @summary Cancel subscription
 */
export const useDeleteSubscription = <TError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSubscription>>, TError,{subscriptionId: number;data: BodyType<DeleteSubscriptionDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteSubscription>>,
        TError,
        {subscriptionId: number;data: BodyType<DeleteSubscriptionDto>},
        TContext
      > => {

      const mutationOptions = getDeleteSubscriptionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
## Switches step 1/2 
Returns all available offers to which a customer can switch from the current subscription.

To list already applied offer switches, use the `GET /subscription-switches` endpoint instead.
  
 * @summary Get available offer switches (step 1/3)
 */
export const getOfferSwitches = (
    params?: GetOfferSwitchesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetOfferSwitches200ResponseDto>(
      {url: `/offer-switches`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOfferSwitchesQueryKey = (params?: GetOfferSwitchesParams,) => {
    return [`/offer-switches`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOfferSwitchesQueryOptions = <TData = Awaited<ReturnType<typeof getOfferSwitches>>, TError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>>(params?: GetOfferSwitchesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferSwitches>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferSwitchesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOfferSwitches>>> = ({ signal }) => getOfferSwitches(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOfferSwitches>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOfferSwitchesQueryResult = NonNullable<Awaited<ReturnType<typeof getOfferSwitches>>>
export type GetOfferSwitchesQueryError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>

/**
 * @summary Get available offer switches (step 1/3)
 */
export const useGetOfferSwitches = <TData = Awaited<ReturnType<typeof getOfferSwitches>>, TError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto | UnprocessableEntityDto>>(
 params?: GetOfferSwitchesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferSwitches>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOfferSwitchesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 
## Switches step 2/2
This endpoint is used to switch the current subscription to another subscription plan.

Use the available `GET /offer-switches` endpoint to get a list of available switches for a to use with this endpoint.
  
 * @summary Create subscription switch from available offer switch (step 2/3)
 */
export const createSubscriptionSwitch = (
    createSubscriptionSwitchPostBodyDto: BodyType<CreateSubscriptionSwitchPostBodyDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<GetSubscriptionSwitches200ResponseDto>(
      {url: `/subscription-switches`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createSubscriptionSwitchPostBodyDto
    },
      options);
    }
  


export const getCreateSubscriptionSwitchMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSubscriptionSwitch>>, TError,{data: BodyType<CreateSubscriptionSwitchPostBodyDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createSubscriptionSwitch>>, TError,{data: BodyType<CreateSubscriptionSwitchPostBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSubscriptionSwitch>>, {data: BodyType<CreateSubscriptionSwitchPostBodyDto>}> = (props) => {
          const {data} = props ?? {};

          return  createSubscriptionSwitch(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateSubscriptionSwitchMutationResult = NonNullable<Awaited<ReturnType<typeof createSubscriptionSwitch>>>
    export type CreateSubscriptionSwitchMutationBody = BodyType<CreateSubscriptionSwitchPostBodyDto>
    export type CreateSubscriptionSwitchMutationError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto>

    /**
 * @summary Create subscription switch from available offer switch (step 2/3)
 */
export const useCreateSubscriptionSwitch = <TError = ErrorType<ForbiddenExceptioDto | NotFoundExceptionDto | ConflictExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSubscriptionSwitch>>, TError,{data: BodyType<CreateSubscriptionSwitchPostBodyDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createSubscriptionSwitch>>,
        TError,
        {data: BodyType<CreateSubscriptionSwitchPostBodyDto>},
        TContext
      > => {

      const mutationOptions = getCreateSubscriptionSwitchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
## Big Screen login step 1/3

Get randomized 6 digit CODE to be displayed on your TV/Big Screen.

The CODE is confirmed and verified on nordiskfilmplus.com/tv or with a QR code on another device like smartphone or computer.
    
 * @summary Get code (step 1/3)
 */
export const getCode = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetGetCode200ResponseDto>(
      {url: `/big-screen`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCodeQueryKey = () => {
    return [`/big-screen`] as const;
    }

    
export const getGetCodeQueryOptions = <TData = Awaited<ReturnType<typeof getCode>>, TError = ErrorType<UnprocessableEntityDto>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCode>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCodeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCode>>> = ({ signal }) => getCode(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCode>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCodeQueryResult = NonNullable<Awaited<ReturnType<typeof getCode>>>
export type GetCodeQueryError = ErrorType<UnprocessableEntityDto>

/**
 * @summary Get code (step 1/3)
 */
export const useGetCode = <TData = Awaited<ReturnType<typeof getCode>>, TError = ErrorType<UnprocessableEntityDto>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCode>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCodeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 
## Big Screen login step 2/3

Verify the CODE VERIFY Big Screen CODE and creates `login_token` with the supplied `refresh_token`.

This route is PROTECTED and requires the customer to be logged in. This could be on another device like a smartphone or computer.
    
 * @summary Verify code (step 2/3)
 */
export const verifyCode = (
    verifyCodeDto: BodyType<VerifyCodeDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PutVerifyCode200ResponseDto>(
      {url: `/big-screen`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: verifyCodeDto
    },
      options);
    }
  


export const getVerifyCodeMutationOptions = <TError = ErrorType<UnauthorizedExceptionDto | ForbiddenExceptioDto | NotFoundExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyCode>>, TError,{data: BodyType<VerifyCodeDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof verifyCode>>, TError,{data: BodyType<VerifyCodeDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyCode>>, {data: BodyType<VerifyCodeDto>}> = (props) => {
          const {data} = props ?? {};

          return  verifyCode(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type VerifyCodeMutationResult = NonNullable<Awaited<ReturnType<typeof verifyCode>>>
    export type VerifyCodeMutationBody = BodyType<VerifyCodeDto>
    export type VerifyCodeMutationError = ErrorType<UnauthorizedExceptionDto | ForbiddenExceptioDto | NotFoundExceptionDto | UnprocessableEntityDto>

    /**
 * @summary Verify code (step 2/3)
 */
export const useVerifyCode = <TError = ErrorType<UnauthorizedExceptionDto | ForbiddenExceptioDto | NotFoundExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyCode>>, TError,{data: BodyType<VerifyCodeDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof verifyCode>>,
        TError,
        {data: BodyType<VerifyCodeDto>},
        TContext
      > => {

      const mutationOptions = getVerifyCodeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
## Big Screen login step 3/3

Use CODE to get `loginToken` or just check code-STATUS Use CODE to request `loginToken`, customers verify the CODE on nordiskfilmplus.com/tv or follow QR-link.

This endpoint can be POLLED and returns the STATUS of the CODE. When the CODE is VERIFIED this endpoints also returns the `loginToken`.
    
 * @summary Get loginToken from Code (step 3/3)
 */
export const getLoginTokenFromCode = (
    loginTokenFromCodeDto: BodyType<LoginTokenFromCodeDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostGetLoginTokenFromCode200ResponseDto>(
      {url: `/big-screen`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: loginTokenFromCodeDto
    },
      options);
    }
  


export const getGetLoginTokenFromCodeMutationOptions = <TError = ErrorType<NotFoundExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getLoginTokenFromCode>>, TError,{data: BodyType<LoginTokenFromCodeDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getLoginTokenFromCode>>, TError,{data: BodyType<LoginTokenFromCodeDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getLoginTokenFromCode>>, {data: BodyType<LoginTokenFromCodeDto>}> = (props) => {
          const {data} = props ?? {};

          return  getLoginTokenFromCode(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetLoginTokenFromCodeMutationResult = NonNullable<Awaited<ReturnType<typeof getLoginTokenFromCode>>>
    export type GetLoginTokenFromCodeMutationBody = BodyType<LoginTokenFromCodeDto>
    export type GetLoginTokenFromCodeMutationError = ErrorType<NotFoundExceptionDto | UnprocessableEntityDto>

    /**
 * @summary Get loginToken from Code (step 3/3)
 */
export const useGetLoginTokenFromCode = <TError = ErrorType<NotFoundExceptionDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getLoginTokenFromCode>>, TError,{data: BodyType<LoginTokenFromCodeDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getLoginTokenFromCode>>,
        TError,
        {data: BodyType<LoginTokenFromCodeDto>},
        TContext
      > => {

      const mutationOptions = getGetLoginTokenFromCodeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Retrieves customer details.
 * @summary Get customer
 */
export const getCustomer = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetCustomer200ResponseDto>(
      {url: `/customer`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCustomerQueryKey = () => {
    return [`/customer`] as const;
    }

    
export const getGetCustomerQueryOptions = <TData = Awaited<ReturnType<typeof getCustomer>>, TError = ErrorType<ForbiddenExceptioDto>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomerQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomer>>> = ({ signal }) => getCustomer(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomerQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomer>>>
export type GetCustomerQueryError = ErrorType<ForbiddenExceptioDto>

/**
 * @summary Get customer
 */
export const useGetCustomer = <TData = Awaited<ReturnType<typeof getCustomer>>, TError = ErrorType<ForbiddenExceptioDto>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCustomerQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update customer details.
 * @summary Update customer
 */
export const putCustomer = (
    putCustomerDto: BodyType<PutCustomerDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PutCustomer200ResponseDto>(
      {url: `/customer`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: putCustomerDto
    },
      options);
    }
  


export const getPutCustomerMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCustomer>>, TError,{data: BodyType<PutCustomerDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putCustomer>>, TError,{data: BodyType<PutCustomerDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCustomer>>, {data: BodyType<PutCustomerDto>}> = (props) => {
          const {data} = props ?? {};

          return  putCustomer(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof putCustomer>>>
    export type PutCustomerMutationBody = BodyType<PutCustomerDto>
    export type PutCustomerMutationError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto>

    /**
 * @summary Update customer
 */
export const usePutCustomer = <TError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCustomer>>, TError,{data: BodyType<PutCustomerDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof putCustomer>>,
        TError,
        {data: BodyType<PutCustomerDto>},
        TContext
      > => {

      const mutationOptions = getPutCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
This endpoint is the first step in changing customer email.

Change customer email and send out a verification email to verify.
  
 * @summary Update customer email (step 1/2)
 */
export const updateEmailUnverified = (
    emailDto: BodyType<EmailDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<unknown>(
      {url: `/email`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: emailDto
    },
      options);
    }
  


export const getUpdateEmailUnverifiedMutationOptions = <TError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEmailUnverified>>, TError,{data: BodyType<EmailDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateEmailUnverified>>, TError,{data: BodyType<EmailDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEmailUnverified>>, {data: BodyType<EmailDto>}> = (props) => {
          const {data} = props ?? {};

          return  updateEmailUnverified(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateEmailUnverifiedMutationResult = NonNullable<Awaited<ReturnType<typeof updateEmailUnverified>>>
    export type UpdateEmailUnverifiedMutationBody = BodyType<EmailDto>
    export type UpdateEmailUnverifiedMutationError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto | void>

    /**
 * @summary Update customer email (step 1/2)
 */
export const useUpdateEmailUnverified = <TError = ErrorType<ForbiddenExceptioDto | UnprocessableEntityDto | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEmailUnverified>>, TError,{data: BodyType<EmailDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateEmailUnverified>>,
        TError,
        {data: BodyType<EmailDto>},
        TContext
      > => {

      const mutationOptions = getUpdateEmailUnverifiedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
This endpoint is the second step in changing customer email.

Confirm new customer email with the `customerToken` from confirmation email.

The customer will be logged out within 1 hour on all devices after changing email.
  
 * @summary Update customer email - Confirm new email (step 2/2)
 */
export const updateEmailConfirm = (
    customerToken: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<unknown>(
      {url: `/email/${customerToken}`, method: 'PATCH'
    },
      options);
    }
  


export const getUpdateEmailConfirmMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEmailConfirm>>, TError,{customerToken: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateEmailConfirm>>, TError,{customerToken: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEmailConfirm>>, {customerToken: string}> = (props) => {
          const {customerToken} = props ?? {};

          return  updateEmailConfirm(customerToken,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateEmailConfirmMutationResult = NonNullable<Awaited<ReturnType<typeof updateEmailConfirm>>>
    
    export type UpdateEmailConfirmMutationError = ErrorType<unknown>

    /**
 * @summary Update customer email - Confirm new email (step 2/2)
 */
export const useUpdateEmailConfirm = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEmailConfirm>>, TError,{customerToken: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateEmailConfirm>>,
        TError,
        {customerToken: string},
        TContext
      > => {

      const mutationOptions = getUpdateEmailConfirmMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
