/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Customer API <customer.nordiskfilmplus.com>
 * Public facing API for NF customers<br><br>
      Download: <a download href="/docs/postman-customer.json">Postman Collection</a>
 * OpenAPI spec version: 1.0
 */
export type GetOfferSwitchesParams = {
subscription_id?: number;
};

export type CreateInitialPayment201 = PostCreateInitialPayment201WithCouponResponseDto | PostCreateInitialPayment201ResponseDto;

export interface PutCustomer200ResponseDto {
  first_name: string;
  id: string;
  last_name: string;
  phone: string;
  updated_at: string;
}

export interface PutCustomerDto {
  first_name: string;
  last_name: string;
  /** Checks if the string is a valid phone number using libphonenumber-js. */
  phone?: string;
}

export interface GetCustomer200ResponseDto {
  country: string;
  created_at: string;
  currency: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  locale: string;
  phone: string;
  updated_at: string;
  /** @nullable */
  validated_at?: string | null;
  /** @nullable */
  verified_at?: string | null;
}

export interface PostGetLoginTokenFromCode200ResponseDto {
  email: string;
  login_token: string;
  status: string;
}

export interface LoginTokenFromCodeDto {
  /**
   * @minLength 6
   * @maxLength 6
   */
  code: string;
}

export interface PutVerifyCode200ResponseDto {
  status: string;
}

export interface VerifyCodeDto {
  /**
   * @minLength 6
   * @maxLength 6
   */
  code: string;
  refresh_token: string;
}

export interface GetGetCode200ResponseDto {
  code: string;
  expiration_time: number;
}

export type SubscriptionSwitchItemDtoPeriod = typeof SubscriptionSwitchItemDtoPeriod[keyof typeof SubscriptionSwitchItemDtoPeriod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionSwitchItemDtoPeriod = {
  daily: 'daily',
  week: 'week',
  month: 'month',
  '3months': '3months',
  '6months': '6months',
  year: 'year',
} as const;

export type SubscriptionSwitchItemDtoDirection = typeof SubscriptionSwitchItemDtoDirection[keyof typeof SubscriptionSwitchItemDtoDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionSwitchItemDtoDirection = {
  upgrade: 'upgrade',
  downgrade: 'downgrade',
} as const;

export interface SubscriptionSwitchItemDto {
  created_at: string;
  currency: string;
  direction: SubscriptionSwitchItemDtoDirection;
  from_offer_id: number;
  id: string;
  next_payment_price: string;
  period: SubscriptionSwitchItemDtoPeriod;
  savings_price: string;
  switch_date_at: string;
  title: string;
  to_offer_id: number;
}

export interface GetSubscriptionSwitches200ResponseDto {
  subscription_switches: SubscriptionSwitchItemDto[];
}

export interface CreateSubscriptionSwitchPostBodyDto {
  subscription_id: number;
  to_offer_id: number;
}

export type OfferSwitchItemDtoPeriod = typeof OfferSwitchItemDtoPeriod[keyof typeof OfferSwitchItemDtoPeriod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferSwitchItemDtoPeriod = {
  daily: 'daily',
  week: 'week',
  month: 'month',
  '3months': '3months',
  '6months': '6months',
  year: 'year',
} as const;

export type OfferSwitchItemDtoDirection = typeof OfferSwitchItemDtoDirection[keyof typeof OfferSwitchItemDtoDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferSwitchItemDtoDirection = {
  upgrade: 'upgrade',
  downgrade: 'downgrade',
} as const;

export interface OfferSwitchItemDto {
  currency: string;
  direction: OfferSwitchItemDtoDirection;
  next_payment_price: string;
  period: OfferSwitchItemDtoPeriod;
  savings_price: string;
  title: string;
  to_offer_id: number;
}

export interface GetOfferSwitches200ResponseDto {
  offer_switches: OfferSwitchItemDto[];
}

export interface DeleteSubscription200ResponseDto {
  message: string;
}

export type DeleteSubscriptionDtoCancellationAnswersV2Item = typeof DeleteSubscriptionDtoCancellationAnswersV2Item[keyof typeof DeleteSubscriptionDtoCancellationAnswersV2Item];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeleteSubscriptionDtoCancellationAnswersV2Item = {
  content: 'content',
  access_already: 'access_already',
  technical: 'technical',
  usage: 'usage',
  price: 'price',
  other: 'other',
  content_unavailable: 'content_unavailable',
  content_old_movies: 'content_old_movies',
  content_new_movies: 'content_new_movies',
  content_american_movies: 'content_american_movies',
  content_series: 'content_series',
  content_kids: 'content_kids',
  content_subtitles: 'content_subtitles',
  access_already_partner: 'access_already_partner',
  access_already_share_household: 'access_already_share_household',
  access_already_share_outside_household: 'access_already_share_outside_household',
  technical_device: 'technical_device',
  technical_interruptions: 'technical_interruptions',
  technical_sound: 'technical_sound',
  technical_usability: 'technical_usability',
  usage_seen: 'usage_seen',
  usage_other_services: 'usage_other_services',
  usage_no_services: 'usage_no_services',
  price_value: 'price_value',
  price_afford: 'price_afford',
  price_other_services: 'price_other_services',
  other_pause: 'other_pause',
  content_other: 'content_other',
  access_already_other: 'access_already_other',
  technical_other: 'technical_other',
  usage_other: 'usage_other',
  price_other: 'price_other',
  other_other: 'other_other',
} as const;

export interface CancellationReasonsV2 {
  /** optional */
  access_already_other?: string;
  /** optional */
  content_other?: string;
  /** optional */
  other_other?: string;
  /** optional */
  price_other?: string;
  /** optional */
  technical_other?: string;
  /** optional */
  usage_other?: string;
}

/**
 * Deprecated, use `cancellation_answers_v2` instead
 * @deprecated
 */
export type CancellationAnswers = typeof CancellationAnswers[keyof typeof CancellationAnswers];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CancellationAnswers = {
  do_not_use: 'do_not_use',
  content: 'content',
  technical: 'technical',
  price: 'price',
  other_streaming_services: 'other_streaming_services',
  other: 'other',
} as const;

export interface DeleteSubscriptionDto {
  cancellation_answers?: CancellationAnswers[];
  /** Cancellation answers */
  cancellation_answers_v2?: DeleteSubscriptionDtoCancellationAnswersV2Item[];
  /**
   * Deprecated, use `cancellation_reasons_v2` instead
   * @deprecated
   * @minLength 2
   * @maxLength 100
   */
  cancellation_reason?: string;
  cancellation_reasons_v2?: CancellationReasonsV2;
}

export interface PatchSubscription200ResponseDto {
  message: string;
}

export type UpdateSubscriptionDtoStatus = typeof UpdateSubscriptionDtoStatus[keyof typeof UpdateSubscriptionDtoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateSubscriptionDtoStatus = {
  active: 'active',
} as const;

export interface UpdateSubscriptionDto {
  coupon_code?: string;
  status: UpdateSubscriptionDtoStatus;
}

/**
 * @nullable
 */
export type SubscriptionItemDtoSwitchToOfferPeriod = typeof SubscriptionItemDtoSwitchToOfferPeriod[keyof typeof SubscriptionItemDtoSwitchToOfferPeriod] | null;


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionItemDtoSwitchToOfferPeriod = {
  daily: 'daily',
  week: 'week',
  month: 'month',
  '3months': '3months',
  '6months': '6months',
  year: 'year',
} as const;

export type SubscriptionItemDtoStatus = typeof SubscriptionItemDtoStatus[keyof typeof SubscriptionItemDtoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionItemDtoStatus = {
  active: 'active',
  hold: 'hold',
  cancelled: 'cancelled',
  terminated: 'terminated',
} as const;

export type SubscriptionItemDtoOfferPeriod = typeof SubscriptionItemDtoOfferPeriod[keyof typeof SubscriptionItemDtoOfferPeriod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionItemDtoOfferPeriod = {
  daily: 'daily',
  week: 'week',
  month: 'month',
  '3months': '3months',
  '6months': '6months',
  year: 'year',
} as const;

export interface SubscriptionItemDto {
  /** @nullable */
  campaign_name: string | null;
  /**
   * The discount amount rate percent is returned in decimal format
   * @nullable
   */
  discount_amount: number | null;
  /** 999 is infinite period | 1 is a month if the Offer period is month etc.. */
  discount_period: number;
  expires_at: number;
  external_payment_id: string;
  /** NF+ IH subscription id */
  id: number;
  /** This can be used to determine if a customer can cancel the subscription or not. */
  is_partner_subscription: boolean;
  next_payment_currency: string;
  next_payment_price: number;
  offer_id: number;
  offer_period: SubscriptionItemDtoOfferPeriod;
  offer_title: string;
  partner_name: string;
  payment_gateway: string;
  payment_method: string;
  /** Cleeng subscription id */
  sms_id: number;
  /** Upodi subscription id */
  smsu_subscription_id: number;
  status: SubscriptionItemDtoStatus;
  /**
   * If a switch is pending this will not be null.
   * @nullable
   */
  switch_to_offer_id: number | null;
  /** @nullable */
  switch_to_offer_period: SubscriptionItemDtoSwitchToOfferPeriod;
  /** @nullable */
  switch_to_offer_title: string | null;
}

export interface GetSubscriptions200ResponseDto {
  subscriptions: SubscriptionItemDto[];
}

export interface PostCreatePaymentDetails201ResponseDto {
  iframeUrl: string;
  redirectUrl: string;
  updateProcessId: string;
}

export interface CreatePaymentDetailsDto {
  failUrl?: string;
  okUrl?: string;
  /**
   * 
@deprecated 2023-08 - Reason: not needed with Upodi behind the scenes, any new payment details will be the default.

Payment details id that you want to replace. User payment details may be listed using: `GET /payment-details`
   * @deprecated
   */
  payment_details_id?: number;
  /** 
- `payment_method_id` => Is used to determine what payment gateway to use. You can skip to leave this out and let the backend decide the default.

The newly created payment details will be set as the default method for the next recurring payment. */
  payment_method_id?: number;
}

export type CreateInitialPaymentDtoOrderId = { [key: string]: any };

export interface CreateInitialPaymentDto {
  failUrl?: string;
  okUrl?: string;
  order_id: CreateInitialPaymentDtoOrderId;
}

export type OrderItemDtoPriceBreakdown = {
  discount_amount?: number;
  discounted_price?: number;
  offer_price?: number;
  tax_value?: number;
};

export type OrderItemDtoId = { [key: string]: any };

export type OrderItemDtoDiscount = {
  applied?: boolean;
  discount_period?: number;
};

export interface OrderItemDto {
  coupon_code: string;
  customer_currency: string;
  discount: OrderItemDtoDiscount;
  id: OrderItemDtoId;
  offer_currency: string;
  offer_id: string;
  /** Price with VAT */
  offer_price: number;
  offer_title: string;
  payment_method_id: number;
  price_breakdown: OrderItemDtoPriceBreakdown;
  /** is `false` if 100% coupon code was used */
  required_payment_details: boolean;
  status: string;
  /** Price with VAT */
  total_price: number;
}

export interface CreateOrderDto {
  coupon_code?: string;
  offer_id: string;
  payment_method_id?: number;
}

export interface PaymentMethodItemDto {
  country: string;
  id: number;
  method_name: string;
}

export interface GetOffers200ResponseDto {
  offers: OfferItemDto[];
}

export type OfferItemDtoPeriod = typeof OfferItemDtoPeriod[keyof typeof OfferItemDtoPeriod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferItemDtoPeriod = {
  daily: 'daily',
  week: 'week',
  month: 'month',
  '3months': '3months',
  '6months': '6months',
  year: 'year',
} as const;

export type OfferItemDtoId = { [key: string]: any };

export interface OfferItemDto {
  currency?: string;
  id: OfferItemDtoId;
  is_tax_included: boolean;
  period?: OfferItemDtoPeriod;
  price?: number;
  title: string;
}

export type PaymentDetailsItemDtoPaymentMethodSpecificParams = {
  card_expiration_date?: string;
  last_card_four_digits?: string;
  variant?: string;
};

export interface PaymentDetailsItemDto {
  active: boolean;
  id: string;
  payment_gateway: string;
  payment_method: string;
  payment_method_specific_params: PaymentDetailsItemDtoPaymentMethodSpecificParams;
  updated_at: string;
}

export type PostCreateInitialPayment201WithCouponResponseDtoDataPayment = {
  id?: number;
  order_id?: number;
};

export type PostCreateInitialPayment201WithCouponResponseDtoData = {
  payment?: PostCreateInitialPayment201WithCouponResponseDtoDataPayment;
  redirect_url?: string;
};

export interface PostCreateInitialPayment201WithCouponResponseDto {
  data: PostCreateInitialPayment201WithCouponResponseDtoData;
  message: string;
}

export interface PostCreateInitialPayment201ResponseDto {
  iframeUrl: string;
  paymentProcessId: string;
  redirectUrl: string;
}

export interface GetInvoice200ResponseDto {
  /** The name of a campaign, if a coupon was used on the last transaction. */
  campaign_name: string;
  customer_country?: string;
  customer_currency?: string;
  customer_email: string;
  customer_id: string;
  customer_locale?: string;
  display_name?: string;
  id: string;
  offer_id: string;
  offer_period: string;
  offer_title: string;
  payment_status?: string;
  period_from: number;
  period_to: number;
  tax_rate: string;
  transaction_date: number;
  transaction_external_data: string;
  transaction_id: string;
  transaction_price_excl_tax: string;
  transaction_price_incl_tax: string;
}

export interface InvoiceSmsuDto {
  /** The name of a campaign, if a coupon was used on the last transaction. */
  campaign_name: string;
  customer_country?: string;
  customer_currency?: string;
  customer_email: string;
  customer_id: string;
  customer_locale?: string;
  display_name?: string;
  id: string;
  offer_id: string;
  offer_period: string;
  offer_title: string;
  payment_status?: string;
  period_from: number;
  period_to: number;
  tax_rate: string;
  transaction_date: number;
  transaction_external_data: string;
  transaction_id: string;
  transaction_price_excl_tax: string;
  transaction_price_incl_tax: string;
}

export interface GetInvoices200ResponseDto {
  invoices: InvoiceSmsuDto[];
}

export type ConflictExceptionDtoStatusCode = typeof ConflictExceptionDtoStatusCode[keyof typeof ConflictExceptionDtoStatusCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConflictExceptionDtoStatusCode = {
} as const;

export interface ConflictExceptionDto {
  error: string;
  message: string;
  statusCode: ConflictExceptionDtoStatusCode;
}

export type NotFoundExceptionDtoStatusCode = typeof NotFoundExceptionDtoStatusCode[keyof typeof NotFoundExceptionDtoStatusCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotFoundExceptionDtoStatusCode = {
} as const;

export interface NotFoundExceptionDto {
  error: string;
  message: string;
  statusCode: NotFoundExceptionDtoStatusCode;
}

export interface PutUpdateCustomerConsents200ResponseDto {
  affected_consents: number;
  message: string;
}

export interface ConsentsDto {
  consents: ConsentItemDto[];
}

export type CustomerConsentItemResponseDtoState = typeof CustomerConsentItemResponseDtoState[keyof typeof CustomerConsentItemResponseDtoState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerConsentItemResponseDtoState = {
  accepted: 'accepted',
  declined: 'declined',
} as const;

export interface CustomerConsentItemResponseDto {
  date: number;
  is_update_needed: boolean;
  name: string;
  state: CustomerConsentItemResponseDtoState;
  version: string;
}

export interface GetCustomerConsents200ResponseDto {
  consents: CustomerConsentItemResponseDto[];
}

export interface AvailableConsentItemResponseDto {
  is_required: boolean;
  is_required_signup: boolean;
  is_required_update: boolean;
  label: string;
  name: string;
  url: string;
  version: number;
}

export interface GetAvailableConsentsFromToken200ResponseDto {
  consents: AvailableConsentItemResponseDto[];
}

export type UnauthorizedExceptionDtoStatusCode = typeof UnauthorizedExceptionDtoStatusCode[keyof typeof UnauthorizedExceptionDtoStatusCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnauthorizedExceptionDtoStatusCode = {
} as const;

export interface UnauthorizedExceptionDto {
  error: string;
  message: string;
  statusCode: UnauthorizedExceptionDtoStatusCode;
}

export interface PostRefreshToken200ResponseDto {
  idToken: string;
}

export interface RefreshTokensDto {
  refresh_token: string;
}

export type PatchReplacePassword200ResponseDtoData = {
  login_token?: string;
};

export interface PatchReplacePassword200ResponseDto {
  data: PatchReplacePassword200ResponseDtoData;
  message: string;
}

export interface UpdatePasswordDto {
  /**
   * @minLength 10
   * @maxLength 60
   */
  password: string;
  /**
   * @minLength 10
   * @maxLength 60
   */
  password_new: string;
}

export type PostResetPassword428ResponseDtoData = {
  redirect_url?: string;
};

export interface PostResetPassword428ResponseDto {
  data: PostResetPassword428ResponseDtoData;
  message: string;
}

export type PostResetPassword200ResponseDtoData = {
  redirect_url?: string;
};

export interface PostResetPassword200ResponseDto {
  data: PostResetPassword200ResponseDtoData;
  message: string;
}

export interface ResetPasswordDto {
  /**
   * @minLength 10
   * @maxLength 60
   */
  password: string;
}

export interface EmailDto {
  email: string;
}

export type PostLoginUnauthorizedExceptionDtoStatusCode = typeof PostLoginUnauthorizedExceptionDtoStatusCode[keyof typeof PostLoginUnauthorizedExceptionDtoStatusCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostLoginUnauthorizedExceptionDtoStatusCode = {
} as const;

export interface PostLoginUnauthorizedExceptionDto {
  error: string;
  message: string;
  statusCode: PostLoginUnauthorizedExceptionDtoStatusCode;
}

export type PostLoginBodyDtoDeviceType = typeof PostLoginBodyDtoDeviceType[keyof typeof PostLoginBodyDtoDeviceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostLoginBodyDtoDeviceType = {
  WEB: 'WEB',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  APPLE_TV: 'APPLE_TV',
  SMART_TV: 'SMART_TV',
  CONSOLE: 'CONSOLE',
  STB: 'STB',
} as const;

export interface PostLoginBodyDto {
  /** Any unique string to identify the device */
  device_id: string;
  device_name?: string;
  device_type: PostLoginBodyDtoDeviceType;
  email: string;
  /**
   * @minLength 10
   * @maxLength 60
   */
  password: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
}

export interface PostLoginResponseDto {
  idToken: string;
  ottToken: string;
  refreshToken: string;
}

export type PostLoginTokenBodyDtoDeviceType = typeof PostLoginTokenBodyDtoDeviceType[keyof typeof PostLoginTokenBodyDtoDeviceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostLoginTokenBodyDtoDeviceType = {
  WEB: 'WEB',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  APPLE_TV: 'APPLE_TV',
  SMART_TV: 'SMART_TV',
  CONSOLE: 'CONSOLE',
  STB: 'STB',
} as const;

export interface PostLoginTokenBodyDto {
  /** Any unique string to identify the device */
  device_id: string;
  device_name?: string;
  device_type: PostLoginTokenBodyDtoDeviceType;
}

export type PostValidateCustomerConflictDtoStatusCode = typeof PostValidateCustomerConflictDtoStatusCode[keyof typeof PostValidateCustomerConflictDtoStatusCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostValidateCustomerConflictDtoStatusCode = {
} as const;

export type PostValidateCustomerConflictDtoMessage = typeof PostValidateCustomerConflictDtoMessage[keyof typeof PostValidateCustomerConflictDtoMessage];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostValidateCustomerConflictDtoMessage = {
  CUSTOMER_ALREADY_VALIDATED: 'CUSTOMER_ALREADY_VALIDATED',
} as const;

export interface PostValidateCustomerConflictDto {
  data: VerifyCustomerDataDto;
  error: string;
  message: PostValidateCustomerConflictDtoMessage;
  statusCode: PostValidateCustomerConflictDtoStatusCode;
}

export interface PostValidateCustomer200ResponseDataItemDto {
  login_token: boolean;
  redirect_url: string;
}

export interface PostValidateCustomer200ResponseDto {
  data: PostValidateCustomer200ResponseDataItemDto;
  message: string;
}

export type ConsentItemDtoState = typeof ConsentItemDtoState[keyof typeof ConsentItemDtoState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsentItemDtoState = {
  accepted: 'accepted',
  declined: 'declined',
} as const;

export interface ConsentItemDto {
  name: string;
  /** Must be unique. Combine profileID and deviceID to get the push consent for a specific device. */
  push_id?: string;
  state: ConsentItemDtoState;
  version: number;
}

export interface SignupValidateCustomerDto {
  consents: ConsentItemDto[];
  first_name: string;
  last_name: string;
  /**
   * @minLength 10
   * @maxLength 60
   */
  password: string;
  /** Checks if the string is a valid phone number using libphonenumber-js. */
  phone?: string;
}

export type GetVerifyCustomerConflictDtoStatusCode = typeof GetVerifyCustomerConflictDtoStatusCode[keyof typeof GetVerifyCustomerConflictDtoStatusCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetVerifyCustomerConflictDtoStatusCode = {
} as const;

export type GetVerifyCustomerConflictDtoMessage = typeof GetVerifyCustomerConflictDtoMessage[keyof typeof GetVerifyCustomerConflictDtoMessage];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetVerifyCustomerConflictDtoMessage = {
  CUSTOMER_ALREADY_VALIDATED: 'CUSTOMER_ALREADY_VALIDATED',
  CUSTOMER_ALREADY_VERIFIED: 'CUSTOMER_ALREADY_VERIFIED',
} as const;

export interface GetVerifyCustomerConflictDto {
  data: VerifyCustomerDataDto;
  error: string;
  message: GetVerifyCustomerConflictDtoMessage;
  statusCode: GetVerifyCustomerConflictDtoStatusCode;
}

export type ForbiddenExceptioDtoStatusCode = typeof ForbiddenExceptioDtoStatusCode[keyof typeof ForbiddenExceptioDtoStatusCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ForbiddenExceptioDtoStatusCode = {
} as const;

export interface ForbiddenExceptioDto {
  error: string;
  message: string;
  statusCode: ForbiddenExceptioDtoStatusCode;
}

export type GetVerifyCustomerOkDtoMessage = typeof GetVerifyCustomerOkDtoMessage[keyof typeof GetVerifyCustomerOkDtoMessage];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetVerifyCustomerOkDtoMessage = {
  CUSTOMER_VERIFIED: 'CUSTOMER_VERIFIED',
} as const;

export interface VerifyCustomerDataDto {
  email: string;
  first_name: string;
  last_name: string;
  marketing_consent_state: boolean;
  /** @nullable */
  phone: string | null;
  redirect_url?: string;
}

export interface GetVerifyCustomerOkDto {
  data: VerifyCustomerDataDto;
  message: GetVerifyCustomerOkDtoMessage;
}

export type UnprocessableEntityDtoStatusCode = typeof UnprocessableEntityDtoStatusCode[keyof typeof UnprocessableEntityDtoStatusCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnprocessableEntityDtoStatusCode = {
} as const;

export interface UnprocessableEntityErrorItemDto {
  field: string;
  message: string;
}

export interface UnprocessableEntityDto {
  error: string;
  errors: UnprocessableEntityErrorItemDto[];
  message: string;
  statusCode: UnprocessableEntityDtoStatusCode;
}

export type PostRegisterUnverifiedCustomerConflictDtoStatusCode = typeof PostRegisterUnverifiedCustomerConflictDtoStatusCode[keyof typeof PostRegisterUnverifiedCustomerConflictDtoStatusCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostRegisterUnverifiedCustomerConflictDtoStatusCode = {
} as const;

export type PostRegisterUnverifiedCustomerConflictDtoData = {
  redirect_url?: string;
};

export interface PostRegisterUnverifiedCustomerConflictDto {
  data: PostRegisterUnverifiedCustomerConflictDtoData;
  error: string;
  message: string;
  statusCode: PostRegisterUnverifiedCustomerConflictDtoStatusCode;
}

export interface PostRegisterUnverifiedCustomerOkDto {
  message: string;
}

export interface SignupUnverifiedCustomerDto {
  email: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
}

